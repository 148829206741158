.modalWrapper {
    position: absolute;
    top: -40%;
    bottom: 0;
    left: 0;
    right: 0;
    background: #50505080;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 60;
  }
  
  .modal {
    max-width: 375px;
    margin: 0 1rem;
    background: white;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    animation: fadeInDown 0.8s;
    z-index: 60;
  }
  .btnClose {
    position: absolute;
    top: 8px;
    right: 8px;
  }
  
  @keyframes fadeInDown {
    0% {
      opacity: 0;
      transform: translateY(-50px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  